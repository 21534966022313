import React from 'react'
import styled from 'styled-components'
import '../styles/spectre.css';

const MoleculePickupNotification = styled.div`

  .sg-location-summary {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
      padding-right: 16px;
      margin-bottom: 20px;
      .wrapper-summary-location {
        display: grid;
        grid-template-rows: 0.3fr 0.2fr 0.2fr;
        grid-template-columns: 1fr 1fr 0.6fr;
        grid-template-areas:
        "sg_title_summary sg_title_summary sg_edit_summary"
        "sg_pickup_date_summary sg_estimated_delivery_summary sg_edit_summary"
        "sg_city_summary sg_city_2_summary sg_edit_summary";
        .es-sg-title-summary {
          grid-area: sg_title_summary; // 🗺
        }
        .es-sg-edit-summary {
          grid-area: sg_edit_summary; // 🗺
          font-size: 14px;
          font-weight: 500;
          color: #0033a1;
          cursor: pointer;
        }
        .es-sg-pickup-date-summary {
          grid-area: sg_pickup_date_summary; // 🗺
          font-family: 'Open Sans',sans-serif;
          font-size: 14px;
          font-weight: 400;
          color: #141b1f;
          span {
          color: #666;
          font-weight: 600;
          }
        }
        .es-sg-estimated-summary {
          font-size: 14px;
          font-weight: 400;
          color: #141b1f;
          padding-left: 6rem;
          span {
          color: #666;
          font-weight: 600;
          font-family: 'Open Sans',sans-serif;
          font-size: 14px;
          }
        }
        .es-sg-estimated-delivery-summary {
          grid-area: sg_estimated_delivery_summary; // 🗺
        }
        .es-sg-city-summary {
          grid-area: sg_city_summary; // 🗺
          margin-top: 20px;
          color: #666;
          font-weight: 600;
          font-size: 12px;
          span {
            font-size: 18px;
            font-weight: 400;
            color: #2b363b;
            text-transform: uppercase;
          }
        }
        .es-sg-city-2-summary {
          grid-area: sg_city_2_summary; // 🗺
          margin-top: 20px;
          color: #666;
          font-weight: 600;
          font-size: 12px;
          padding-left: 6rem;
          span {
            font-size: 18px;
            font-weight: 400;
            color: #2b363b;
            text-transform: uppercase;
          }
        }
      }
    }
    .es-t-page-title {
    font-family: 'Teko', sans-serif;
    margin-bottom: 10px;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    line-height: 1.5;
    font-size: 30px;
    color: #141b1f;
  }
`;

const EchoShipMiscComponent = () => {
  return (
    <MoleculePickupNotification>
<div className="sg-location-summary">
        <div className="wrapper-summary-location">
          <div className="es-sg-title-summary">
            <div className="es-t-page-title">Summary</div>
          </div>
          <div className="es-sg-edit-summary">Edit Origin and Destination</div>
          <div className="es-sg-pickup-date-summary">
            <span>Pickup Date</span> <br />
            Mon, Mar 4 <br />
            4:30 AM - 5:00 PM
          </div>
          <div className="es-sg-estimated-summary">
            <span>Estimated Delivery</span> <br />
            Tue, Mar 12 <br />
          </div>
          <div className="es-sg-city-summary">
            <span>Philadelphia, PA</span> <br />
            19104
            <br />
          </div>
          <div className="es-sg-city-2-summary">
            <span>Beverly Hills, CA</span> <br />
            90210
            <br />
          </div>
        </div>
      </div>
    </MoleculePickupNotification>  
  )
}

export default EchoShipMiscComponent;