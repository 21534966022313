import React from 'react'
import styled from 'styled-components'
import '../styles/spectre.css';

const MoleculePickupNotification = styled.div`
  margin-bottom: 35px;
  .es-t-page-title {
    font-family: 'Teko', sans-serif;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    line-height: 1.5;
    font-size: 30px;
    color: #141b1f;
    margin-bottom: 8px;
  }
`;

const EchoShipMiscComponent = () => {
  return (
    <MoleculePickupNotification>
      <div className="sg-dispatch-options">
        <div className="es-t-page-title">Dispatch Options</div>
        <div className="form-group es-i-state">
          <label className="form-label" htmlFor="input-state">
            How should your order be placed?
          </label>
          <select
            className="form-select select-lg"
            style={{
              width: "100%"
            }}
          >
            <option value>
              My Echo Rep will dispatch my shipment (recommended)
            </option>
            <option value>
              I will contact the carrier to dispatch the shipment myself
            </option>
            <option value>My shipment will be loaded on a drop trailer</option>
          </select>
        </div>
      </div>
    </MoleculePickupNotification>  
  )
}

export default EchoShipMiscComponent;