import React from 'react'
import styled from 'styled-components'

// 💅
const ButtonPrevious = styled.button `
  font-family: 'Open Sans';
  background-color: #a9adaf;
  border: 0.1rem solid #a9adaf;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  height: 45px;
  letter-spacing: 0.1rem;
  padding: 0 1.5rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
    &:focus,
    &:hover {
      color: #fff;
      outline: 0;
      background-color: #8F9496;
      border-color: #8F9496;
  }
`;

// 🕳
const Button = ( ) => {
  return (
      <ButtonPrevious>Previous</ButtonPrevious>
  )
}
export default Button


