import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const OrganismSummaryBookShipment = styled.div`

    display: grid;
    grid-template-rows: 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.4fr 0.05fr 0.4fr 0.5fr 0.5fr 0.5fr;
    grid-template-columns: 0.5fr 0.5fr;
    grid-template-areas:
    "sg_title sg_title"
    "sg_underline sg_underline"
    "sg_origin sg_origin_details"
    "sg_destination sg_destination_details"
    "sg_underline_one sg_underline_one"
    "sg_shipment_total sg_shipment_total_price"
    "sg_underline_two sg_underline_two"
    "sg_total sg_total_price"
    "sg_book_shipment sg_book_shipment"
    "sg_disclaimer_shipment_rates sg_disclaimer_shipment_rates"
    "sg_disclaimer_hazmat sg_disclaimer_hazmat";

`;

const SgTitle = styled.p`
  grid-area: sg_title;
  font-family: 'Teko', sans-serif;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  line-height: 1.5;
  font-size: 30px;
  padding-top: 10px;
  color: #141b1f;
`;

const SgUnderline = styled.div`
  grid-area: sg_underline;
  margin-top: 0px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #e7edf0;
`;

const SgOrigin = styled.p`
  grid-area: sg_origin;
  color: #141b1f;
  font-weight: 400;
  font-size: 15px;
  align-self: end;
  span {
  font-size: 12px;
  font-weight: 600;
  color: #666;
  }
`;

const SgOriginDetails = styled.p`
  grid-area: sg_origin_details;
  color: #141b1f;
  font-weight: 400;
  font-size: 15px;
  align-self: end;
`;

const SgDestination = styled.p`
  grid-area: sg_destination;
  color: #141b1f;
  font-weight: 400;
  font-size: 15px;
  align-self: end;
  span {
  font-size: 12px;
  font-weight: 600;
  color: #666;
  }
`;

const SgDestinationDetails = styled.p`
  grid-area: sg_destination_details;
  color: #141b1f;
  font-weight: 400;
  font-size: 15px;
  align-self: end;
`;

const UnderlineBookOne = styled.div`
    border-bottom: 1px solid #e7edf0;
    grid-area: sg_underline_one;
`;

const SgShipmentTotal = styled.div`
  grid-area: sg_shipment_total;
  justify-self: start;
  font-size: 14px;
  line-height: 1.42857143;
  color: #141b1f;
  font-weight: 400;
  align-self: center;
`;

const SgShipmentTotalPrice = styled.div`
  grid-area: sg_shipment_total_price;
  justify-self: end;
  align-self: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.42857143;
  color: #141b1f;
`;

const UnderlineBookTwo = styled.div`
    border-top: 1px solid #e7edf0;
    grid-area: sg_underline_two;
`;

const SgTotal = styled.div`
  grid-area: sg_total;
  align-self: center;
  color: #666;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
`;

const SgTotalPrice = styled.div`
  grid-area: sg_total_price;
  align-self: center;
  justify-self: end;
  font-size: 18px;
  font-weight: 400;
  color: #2b363b;
  margin-bottom: 5px;
`;

const ButtonBookShipment = styled.button`
  grid-area: sg_book_shipment;
  grid-column-start: span 2;
  font-family: 'Open Sans';
  background-color: #3daf2c;
  border: 0.1rem solid #3daf2c;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  display: block;
  width: 100%;
  font-size: 14px !important;
  font-weight: 700;
  height: 45px;
  letter-spacing: 0.02rem;
  padding: 0 2rem;
  align-items: stretch;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // removes default border highlighting 
  &:focus {
    outline: -webkit-focus-ring-color none;
  }
  a {
    text-decoration: none !important;
    color: white;
  }
`;

const DisclaimerShipmentRates = styled.div`
  grid-area: sg_disclaimer_shipment_rates;
  border-bottom: 1px solid #e7edf0;
  font-size: 10px;
  color: #a9adaf;
  font-weight: 400px;
  padding-bottom: 20px;
  span {
    font-style: italic;
  }
`;

const DisclaimerHazmat = styled.p`
  grid-area: sg_disclaimer_hazmat;
  font-size: 10px;
  color: #a9adaf;
  font-weight: 400px;
  padding-top: 20px;
  span {
    font-style: italic;
  }
`;





const EchoShipOrganismComponent = () => {
  return (

    <OrganismSummaryBookShipment>

      <SgTitle>Summary</SgTitle>
      <SgUnderline></SgUnderline>
      <SgOrigin>
          <span>ORIGIN</span><br />
          CHICAGO, IL <br />
          60654 US
      </SgOrigin>
      <SgOriginDetails>
          <span>Thu, Mar 14</span><br />
          8:00 AM - 5:00 PM
      </SgOriginDetails>
      <SgDestination>
          <span>DESTINATION</span><br />
          MACOMB, MI <br />
          48042 US
      </SgDestination>
      <SgDestinationDetails>
          <span>Fri, Mar 15</span><br />
      </SgDestinationDetails>


      <UnderlineBookOne></UnderlineBookOne>
      <SgShipmentTotal>
          Shipment Total 
      </SgShipmentTotal>


      <SgShipmentTotalPrice>$203.51</SgShipmentTotalPrice>
      <UnderlineBookTwo></UnderlineBookTwo>

      <SgTotal>
          Total
      </SgTotal>
      <SgTotalPrice>$203.51</SgTotalPrice>


      <ButtonBookShipment><Link to="/quote/Confirmation/">Book Shipment</Link></ButtonBookShipment>


      <DisclaimerShipmentRates>
          <span>SHIPMENT RATES</span><br />
          Rates are only applicable to shipments that take up less than 12 feet of space on a trailer. Accessorial charges that are not displayed in rate results will be applied at the time of invoice creation, and will be controlled by the carrier’s General Rules Tariff. The rate for shipments that are re-weighed or re-classed by the carrier may change. Transit times are estimates and are only guaranteed when noted in select carrier options. Additional terms applicable to this shipment can be found here.
      </DisclaimerShipmentRates>
      <DisclaimerHazmat>
          <span>HAZMAT ITEM TERMS & CONDITIONS</span><br />
          Shipper represents that the information provided is complete and accurate and that any hazardous materials have been properly classified, described, packaged, marked, and labeled and are in proper condition for transportation according to the applicable regulations of the DOT and all applicable law. Echo is only passing information to the carrier and is not performing any pre-transportation functions, all of which are being performed by Shipper.
      </DisclaimerHazmat>


    </OrganismSummaryBookShipment>

  )
}

export default EchoShipOrganismComponent;