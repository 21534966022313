import React from 'react'
import styled from 'styled-components'

// Import Page Level Components
import MoleculePickUpNotification from '../../../Molecules/MoleculePickupNotification.js'
import MoleculeDispatchOptions from '../../../Molecules/MoleculeDispatchOptions.js'
import MoleculeTextSummary from '../../../Molecules/MoleculeTextSummary.js'
import AccordionComponentCustom3 from '../../ContentGrids/Shipments/ShipmentDetails/Components/AccordionComponentCustom3.js'

import OrganismSummaryBookShipment from '../../../PageComponents/Summary/OrganismSummaryBookShipment.js'

import NavigationReviewAndDispatch from '../../../PageComponents/Navigation/Quote/NavigationReviewAndDispatch.js'



const GContentBookReviewAndDispatch = styled.div`
  body {
    @import url('https://fonts.googleapis.com/css?family=Teko');
    font-family: 'Teko', sans-serif;
  }
  margin: 0 auto;
  max-width: 1360px;
  background-color: #fff !important;
  // 🖋
  font-family: 'Open Sans';
  // 📰
  display: grid;
  grid-template-rows: 1fr 0.1fr;
  grid-template-columns: 1fr 0.45fr;
  grid-template-areas:
    "sg_add_items sg_summary"
    "sg_navigation_stepper sg_summary";

  .SGAddToShipment {
    grid-area: sg_add_items; // 🗺
  }
  .SGSummary {
    grid-area: sg_summary; // 🗺
  }
  .SGNavigationStepper {
    grid-area: sg_navigation_stepper; // 🗺
    background: none !important;
    align-self: start;
  }
`;

const SGAddToShipment = styled.div`
  padding-right: 20px;
  margin-top: 20px;
`;
const SGSummary = styled.div`
  padding-left: 20px;
  padding-right: 10px;
  margin-top: 20px;
`;
const SGNavigationStepper = styled.div`
`;





const GridComponentAddItems = () => {
  return (

    <GContentBookReviewAndDispatch>

        <SGAddToShipment>
            
            <MoleculePickUpNotification></MoleculePickUpNotification>
            <MoleculeDispatchOptions></MoleculeDispatchOptions>
            <MoleculeTextSummary></MoleculeTextSummary>


            <AccordionComponentCustom3 
              accordionHeader="Origin"
              accordionTitle="Echo Global Logistics"
              accordionSubTitle="Chicago"
              accordionTitleRight="Chicago, IL"
              accordionSubTitleRight="60610"
              htmlFor="accordion-origin"
            />
            <AccordionComponentCustom3 
              accordionHeader="Destination"
              accordionTitle="Echo Global Logistics"
              accordionSubTitle="Long Beach"
              accordionTitleRight="Long Beach, CA"
              accordionSubTitleRight="90802"
              htmlFor="accordion-origin"
            />
            <AccordionComponentCustom3 
              accordionHeader="Shipment Items (1)"
              accordionTitle="Echo Global Logistics"
              accordionSubTitle="Long Beach"
              accordionTitleRight="Long Beach, CA"
              accordionSubTitleRight="90802"
              htmlFor="accordion-origin"
            />
            <AccordionComponentCustom3 
              accordionHeader="Carrier Information"
              accordionTitle="Echo Global Logistics"
              accordionSubTitle="Long Beach"
              accordionTitleRight="Long Beach, CA"
              accordionSubTitleRight="90802"
              htmlFor="accordion-origin"
            />


        </SGAddToShipment>


        <SGSummary>
            <OrganismSummaryBookShipment></OrganismSummaryBookShipment>
        </SGSummary>

        <SGNavigationStepper>
            <NavigationReviewAndDispatch></NavigationReviewAndDispatch>
        </SGNavigationStepper>

    </GContentBookReviewAndDispatch>

  )
}

export default GridComponentAddItems;