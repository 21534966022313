import React from 'react'
import styled from 'styled-components'

import '../../../../../styles/spectre.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons'

library.add(faChevronCircleDown)



const AccordionComponentCustom3 = styled.div`
.es-c-accordion--destination {
  // 🎛
  // 🎨

  transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
  &:hover {
  }
  // 🖋
  font-family: 'Open Sans';
    .es-accordion-wrapper {
    // 📰
    display: grid;
    grid-template-rows: 0.2fr 0.1fr;
    grid-template-columns: 1fr 1fr 0.3fr;
    grid-template-areas:
      "accordion_header"
      "accordion_body";

    .accordion-header {
      grid-area: accordion_header; // 🗺
      background-color: #505C61;
      color: #fff;
      border-radius: 3px 3px 0px 0px;
      // 💅
      transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
      
      .es-accordion-wrapper--header {
        min-width: 900px;
        min-height: 70px;
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr;
        grid-template-areas:
          "sg_title sg_title sg_title sg_city sg_carrot"
          "sg_subtitle sg_subtitle sg_subtitle sg_zip sg_carrot"; 
        

        .es-sg-carrot {
          grid-area: sg_carrot; // 🗺
          align-self: center;
          justify-self: center;
          padding-left: 40px;
          }
        }
        .es-sg-title {
          grid-area: sg_title; // 🗺
          padding-left: 18px;
          align-self: center;
          line-height: 24px;
          font-size: 18px;
        }
        .es-sg-subtitle {
          grid-area: sg_subtitle; // 🗺
          padding-left: 18px;
          align-self: center;
          font-size: 12px;
          font-weight: 500;
        }
        .es-sg-city {
          grid-area: sg_city; // 🗺
          align-self: center;
          line-height: 24px;
          font-size: 18px;
          justify-self: end;
        }
        .es-sg-zip {
          grid-area: sg_zip; // 🗺
          align-self: center;
          font-size: 12px;
          font-weight: 500;
          justify-self: end;
        }
      }  
    }
    .accordion-body {
      grid-area: accordion_body; // 🗺
      background: #FFFFFF;
      border: 1px solid #BBBBBB;
      border-radius: 0px 0px 3px 3px;
      .es-accordion-wrapper--body {
        padding-top: 20px;
        padding-bottom: 20px;
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          "sg_address"
          "sg_title"
          "sg_info"; 
        

        .es-sg-address {
          grid-area: sg_address; // 🗺
          align-self: center;
          line-height: 24px;
          font-size: 18px;
          padding-left: 20px;
        }
        .es-sg-title {
          grid-area: sg_title; // 🗺
          align-self: center;
          font-size: 12px;
          color: #666666;
          font-weight: 500;
          padding-left: 20px;
        }
        .es-sg-info {
          grid-area: sg_info; // 🗺
          align-self: center;
          line-height: 24px;
          font-size: 18px;
          padding-left: 20px;
        }
      } 
    }
  }

`;

const AccordionHeader = styled.div`
    font-family: Teko,'Helvetica Neue',Helvetica,Arial,sans-serif;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    line-height: 1.5;
    font-size: 30px;
    color: #141b1f;
`

const AccordionComponent = (props) => {
  return (
    <AccordionComponentCustom3>
      <AccordionHeader>{props.accordionHeader}</AccordionHeader>
      <div className="es-c-accordion--destination">
        <div className="es-accordion-wrapper">
          <div className="accordion">
            <input
              type="checkbox"
              id={props.htmlFor}
              name="accordion-checkbox"
              hidden
            />

            <label className="accordion-header" htmlFor={props.htmlFor}>

              <div className="es-accordion-wrapper--header">
                <div className="es-sg-carrot">
                    <FontAwesomeIcon icon="chevron-circle-down" />
                </div>
                <div className="es-sg-title">{props.accordionTitle}</div>
                <div className="es-sg-subtitle">{props.accordionSubTitle}</div>
                <div className="es-sg-city">{props.accordionTitleRight}</div>
                <div className="es-sg-zip">{props.accordionSubTitleRight}</div>
              </div>
            </label>

            <div className="accordion-body">

              <div className="es-accordion-wrapper--body">
                <div className="es-sg-address">
                  37 Beverly Ridge Ter Hills City Hall <br />
                  Beverly HILLS, CA 90210
                  <br />
                  US
                  <br />
                </div>
                <div className="es-sg-title">Contact</div>
                <div className="es-sg-info">
                  Nate <br />
                  5076843488
                </div>
              </div>
            </div>
          </div>
          {}
        </div>
      </div>
  

    </AccordionComponentCustom3>
  )
}

export default AccordionComponent;