import React from 'react'
import styled from 'styled-components'

// Import Page Level Components
import NavPrimary from '../../components/layouts/NavPrimary.js'
import SecondaryNav from '../../components/layouts/NavSecondary.js'
import ProgressIndicatorStateFour from '../../components/layouts/ProgressIndicator/ProgressIndicatorStateFour.js'
import Footer from '../../components/layouts/Footer.js'
import GContentBookReviewAndDispatch from '../../components/ContentGrids/BookShipment/ReviewAndDispatch.js'

const PGComponentBookReviewAndDispatch = styled.div`
  height: 100vh;
  background-color: #fff;
  // 🖋
  font-family: 'Open Sans';

  // 📰
  display: grid;
  grid-template-rows: 0.1fr 0.3fr 0.2fr 5fr 0.2fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    "nav_secondary"
    "nav_primary"
    "progress_indicator"
    "g_content"
    "g_footer";

  .NavPrimary {
    grid-area: nav_primary; // 🗺
    align-self: start;
  }
  .SecondaryNav {
    grid-area: nav_secondary; // 🗺
    align-self: start;
  }
  .ProgressIndicatorStateTwo {
    grid-area: progress_indicator; // 🗺
    align-self: start;
  }
  .ComponentContent {
    grid-area: g_content; // 🗺
  }
  .Footer {
    grid-area: g_footer; // 🗺
    align-self: end;
  }
`
const GComponentContent = styled.div`
  width:100%;
`

const EchoShipPageGridComponent = () => {
  return (
    <PGComponentBookReviewAndDispatch>
      <SecondaryNav></SecondaryNav>
      <NavPrimary></NavPrimary>
      <ProgressIndicatorStateFour></ProgressIndicatorStateFour>
        <GComponentContent>
          <GContentBookReviewAndDispatch>
            
          </GContentBookReviewAndDispatch>
        </GComponentContent>
      <Footer></Footer>
    </PGComponentBookReviewAndDispatch>
  )
}

export default EchoShipPageGridComponent;